
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        /** @type {import('vue').PropOptions<MagazineFrontend.Author | null>} */
        author: {
            type: Object,
            default: null,
        },
        datetime: {
            type: String,
            default: null,
        },
        datestring: {
            type: String,
            default: null,
        },
    },
    computed: {
        /** @return {{ large?: string, original?: string } | undefined} */
        headerImage() {
            return this.item.image && this.item.image.image;
        },
        /** @return {{ backgroundImage: string, backgroundColor: string, color: string }} */
        headerStyle() {
            return {
                backgroundImage: this.headerImage ? `url(${this.headerImage.large || this.headerImage.original})` : '',
                backgroundColor: (this.headerImage ? this.item.image.avgColor : null) || (this.item.bg && this.item.bg.hex),
                color: this.item.color && this.item.color.hex,
            };
        },
    },
};
