import { render, staticRenderFns } from "./magazine-content.vue?vue&type=template&id=45d4f257&scoped=true&"
import script from "./magazine-content.vue?vue&type=script&lang=js&"
export * from "./magazine-content.vue?vue&type=script&lang=js&"
import style0 from "./magazine-content.vue?vue&type=style&index=0&id=45d4f257&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d4f257",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MagazineHeader: require('/home/node/app/app/components/magazine-header.vue').default,MagazineImage: require('/home/node/app/app/components/magazine-image.vue').default,TidalEmbed: require('/home/node/app/node_modules/@tidal/styles-embed/src/components/tidal-embed.vue').default,MagazineListFull: require('/home/node/app/app/components/magazine-list-full.vue').default,MagazineListFiftyFifty: require('/home/node/app/app/components/magazine-list-fifty-fifty.vue').default,TidalSectionLabel: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-section-label.vue').default,TidalArticleCards: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-article-cards.vue').default,TidalArticleList: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-article-list.vue').default,TidalArticleCardTop: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-article-card-top.vue').default,TidalArticleCardFeatured: require('/home/node/app/node_modules/@tidal/styles-content/src/components/tidal-article-card-featured.vue').default})
