
export default {
    props: {
        /** @type {import('vue').PropOptions<Record<string, any>[]>} */
        blocks: {
            type: Array,
            required: true,
        },
        author: {
            type: Object,
            default: null,
        },
        datetime: {
            type: String,
            default: null,
        },
        datestring: {
            type: String,
            default: null,
        },
        componentBg: {
            type: String,
            default: null,
        },
    },
    methods: {
        /**
         * @param {number} i
         * @return {boolean}
         */
        checkParagraph(i) {
            if (!this.blocks[i + 1]) {
                return true;
            }

            return this.blocks[i + 1].blockName !== 'core/paragraph';
        },
        /**
         * @param {number} i
         * @return {boolean}
         */
        checkQA(i) {
            if (!this.blocks[i + 1]) {
                return true;
            }

            return this.blocks[i + 1].blockName !== 'tidal-blocks/q-and-a';
        },
        /**
         * @param {any} card
         * @return {Record<string, any>}
         */
        getArticleCardTop(card) {
            return {
                ...card,
                aspectRatio: card.image && card.image.aspectRatio,
                avgColor: card.image && card.image.avgColor,
                image: card.image && card.image.image,
                sizes: card.image && card.image.sizes,
            };
        },
    },
};
