
export default {
    inheritAttrs: false,
    props: {
        image: {
            type: Object,
            default: null,
        },
        sizes: {
            type: Object,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: '',
        },
        caption: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: Number,
            default: null,
        },
        aspectRatio: {
            type: Number,
            default: null,
        },
        avgColor: {
            type: String,
            default: null,
        },
        align: {
            type: String,
            default: null,
        },
        margins: {
            type: Boolean,
            default: true,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
        lazyload: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        /** @return {string | null} */
        displayWidth() {
            if (this.maxWidth) {
                return this.maxWidth + 'px';
            }

            if (this.fullwidth || !this.sizes || this.align === 'wide') {
                return null;
            }

            return this.sizes.original ? this.sizes.original + 'px' : null;
        },
        /** @return {string} */
        displayImage() {
            if (this.image) {
                if (!this.image.medium) {
                    return this.image.original;
                }

                return this.image;
            }

            return this.url;
        },
    },
};
